<template>
  <div class="note">
    <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

    <div class="note-header" v-if="note">
      <div class="note-header__title">
        <input v-model="note.title" class="note-header__input" :disabled="editable === false">
      </div>
      <div class="note-header__save">
        <button v-if="editable === true" class="note-header__btn" @click="saveNote(note)"><i class="fas fa-save"></i> Save</button>
        <button v-else class="note-header__btn" @click="editable = true"><i class="fas fa-edit"></i> Edit</button>
      </div>
    </div>

    <quill-editor
        ref="quillEditor"
        :class="{ 'editable': editable }"
        v-if="note"
        v-model="note.body"
        :options="editorOption"
        :disabled="!editable"
        @change="onEditorChange($event)"
    />
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapActions, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'


export default {
  name: 'Index',
  components: {
    quillEditor,
    Loading
  },
  data () {
    return {
      editable: false,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ],
          clipboard: {
            matchVisual: false
          },
          history: {
            delay: 2000,
            maxStack: 100,
            userOnly: true
          }
        }
      },
      note: {
        title: '',
        body: ''
      }
    }
  },
  mounted () {
    if (typeof this.$route.params.id !== 'undefined') {
      this.loadNote(this.$route.params.id)
          .then(note => {
            this.editable = false
            this.note = { ...note }

            return note
          })
    }
  },
  computed: {
    ...mapGetters('Notes', ['isLoading']),
  },
  watch: {
    $route() {
      this.$refs.quillEditor.quill.history.clear()
      this.$refs.quillEditor.quill.setText('')
      this.note = {
        title: '',
        body: ''
      }

      if (typeof this.$route.params.id !== 'undefined') {
        this.loadNote(this.$route.params.id)
          .then(note => {
            this.editable = false
            this.note = { ...note }

            return note
          })
      }
    }
  },
  methods: {
    ...mapActions('Notes', ['loadNote', 'editNote']),
    saveNote (note) {
      if (note === null) {
        return;
      }

      const editNote = { ...note }

      // editNote.body = editNote.body.replace('<p><br></p>', '') // hack for quill, replace empty line

      this.editNote(editNote)
        .then(() => {
          this.editable = false
        })
    },
    onEditorChange({ html }) {
      this.note.body = html
    }
  }
}
</script>

<style lang="scss" scoped>
.note {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  z-index: 0;
}

.quill-editor::v-deep {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;
  margin-bottom: 20px;

  .ql-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.8);
    overflow-y: auto;
  }
  .ql-toolbar {
    background: rgba(255, 255, 255, 0.9);
    display: none;
    /*opacity: 0;
    visibility: hidden;
    transition: all 0.3s;*/
  }
  .ql-editor {
    flex-grow: 1;
    font-size: 1rem;
    overflow-y: visible;
    color: black;
    max-height: 300px;
  }

  &.editable {
    .ql-toolbar {
      display: block;
      /*opacity: 1;
      visibility: visible;
      transition: all 0.3s;*/
    }
  }
}



.note-header {
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  &__title {
    flex-grow: 1;
  }

  &__save {
    width: 150px;
    margin-left: 30px;
  }

  &__input {
    width: 100%;
    padding: 7px 15px 5px;
    line-height: 1;
    font-size: 18px;
    border: none;
    border-radius: 2px;
    background-color: rgba(255, 255,255,.8);
    backdrop-filter: blur(25px);

    &:disabled {
      background-color: rgba(255, 255,255,.6);
      color: #000;
    }
  }

  &__btn {
    width: 100%;
    padding: 6px 15px;
    line-height: 1;
    font-size: 18px;
    border-radius: 2px;
    border: none;
    background: #82cff1;
    background: linear-gradient(180deg, #82cff1 0, #38aeea);

    &:hover {
      background: #38aeea;
      background: linear-gradient(0deg, #82cff1 0, #38aeea);
    }
  }
}
</style>
